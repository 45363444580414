<template>
<div>
    <page-header :title="`Thêm mới danh mục`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="12">
                <b-card lg="6">
                    <b-form-group>
                        <label>Tên danh mục <span class="red">*</span></label>
                        <b-input v-model="form.name" placeholder="Nhập tên danh mục" />
                    </b-form-group>
                    <b-form-group col-lg="4">
                        <label>Logo <span class="red">*</span></label>
                        <a-thumbnail label="Chọn logo" :value="form.preview" @input="value => form.preview = value" />
                    </b-form-group>
                    <b-form-group>
                        <label>Thứ tự</label>
                        <b-input type="number" v-model.number="form.order" min="1" max="50" placeholder="Nhập thứ tự" />
                    </b-form-group>
                    <b-form-group>
                        <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                            {{addErrorMessage}}
                        </div>
                        <b-button variant="success" class="mr-2" @click.prevent="create">
                            Lưu
                        </b-button>
                        <b-button variant="danger" @click.prevent="toListView">
                            Huỷ
                        </b-button>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import AThumbnail from './partials/a-thumbnail';
import {
    httpClient
} from "../../../_utils/httpClient";
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        AThumbnail,
        PageHeader
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thiết lập danh mục',
                to: '/admin/config-category',
            }, {
                text: 'Thêm mới',
                to: '#',
                active: true,
            }, ],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                config: 'category',
                name: '',
                preview: '',
                order: 1,
            },
            loading: false,
            underUsers: [],
        };
    },
    methods: {
        async toListView() {
            await this.$router.push({
                name: 'admin.config.category'
            })
        },
        async getData() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'category'
                }
            })
            if (response.status === 200 && response.data.code === 0) {
                this.entries = response.data.data
            }
        },
        validator() {
            if (this.form.name < 1 || this.form.name == null) {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Tên danh mục không được trống`;
                return false;
            }
            if (this.form.preview == null || this.form.preview == "") {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Logo không được trống`;
                return false;
            }            
            return true
        },
        async getUnderUser() {
            let response = await httpClient.get(`user/under`)
            let responseData = response.data;
            if (responseData.code === 0) {
                this.underUsers = responseData.data
            }
        },
        async create() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            if(!this.form.order) this.form.order = 0;
            let payload = {
                ...this.form,
            }
            await Swal.queue([{
                title: 'Thêm mới danh mục này?',
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`config`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Thêm mới danh mục thành công',
                        });
                        await this.toListView()
                    } else {
                        Swal.insertQueueStep({
                            title: 'Thêm mới danh mục thành công',
                        });
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                },
            },]);
        },
    },
    async created() {
        await this.getUnderUser();
        this.getData();
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}
</style><style>
.red {
    color: red;
}
</style>
